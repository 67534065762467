import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box'
import Alert from '@mui/material/Alert';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Progress, ResponseErrorPanel } from '@backstage/core-components';
// @ts-ignore
import useHashParam from 'use-hash-param'
import { useQueryJiraDashboardSettings, useQueryJiraReleaseItem, useQueryJiraReleaseList } from '../../hooks/useQueryJira';
import { ReleaseListComponent } from '../ReleaseComponent/ReleaseList';
import { ReleaseItemsTableComponent } from '../ReleaseComponent/ReleaseItemsTable';

interface Props {
}

const PageHeading = (heading: string) => <Typography variant='h1' fontSize={24}>{heading}</Typography>
const ColumnHeading = (heading: string) => <Typography variant='h2' fontSize={14}>{heading}</Typography>

/**
 * Smart fetching component
 * @returns 
 */
export const ReleaseDashboardFetchComponent: React.FC<Props> = () => {
  const [projectName, setProjectName] = useHashParam('project')
  const [boardKey, setBoardKey] = useHashParam('board')
  const [releaseId, setReleaseId] = useHashParam('release')

  // Fetch dashboard settings so we can scaffold it
  const { data: dashboardSettings, isFetching: isFetchingDashboard, error: fetchDashboardError } =
    useQueryJiraDashboardSettings()

  // Fetch releases list query
  const { data: releases, isFetching: isFetchingList, error: fetchReleaseError } =
    useQueryJiraReleaseList(boardKey)

  // Fetch release items query
  const { data: releaseItems, isFetching: isFetchingReleaseItems, error: fetchReleaseItemsError } =
    useQueryJiraReleaseItem(releases?.find(x => x.id === releaseId))

  const { projects } = dashboardSettings || {}

  const boardTabs = useMemo(() => {
    return projects?.find(x => x.name === projectName)?.boards
  }, [projects, projectName])

  const handleProjectChanged = (value: string) => {
    setProjectName(value)
    setBoardKey('')
    setReleaseId('')
  }

  const handleBoardChanged = (value: string) => {
    setBoardKey(value)
  }

  const handleReleaseChanged = (value: string) => {
    setReleaseId(value)
  }

  return (
    <>
      {/* Loading */}
      {isFetchingDashboard && <Progress />}
      {/* Error */}
      {fetchDashboardError && <ResponseErrorPanel defaultExpanded error={fetchDashboardError!} title='Fetching JIRA Dashboard' />}
      {/* All good */}
      {!isFetchingDashboard && !fetchDashboardError &&
        <Box padding={5}>
          {PageHeading('JIRA Releases')}
          {!projects?.length && <Alert variant='outlined' severity="warning">No projects configured</Alert>}
          {projects?.length &&
            <Box paddingY={2}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={3} lg={2}>
                  {ColumnHeading('Projects & Releases')}
                  <FormControl fullWidth size='small'>
                    <InputLabel id="project-select-label">Select a Project</InputLabel>
                    <Select
                      labelId="project-select-label"
                      id="project-select"
                      value={projectName}
                      onChange={(e) => handleProjectChanged(e.target.value)}
                      autoWidth
                      label="Select a Project"
                    >
                      {projects?.map(({ name }) => <MenuItem key={name} value={name}>{name}</MenuItem>)}
                    </Select>
                  </FormControl>
                  {/* Fetch releases error */}
                  {fetchReleaseError && <ResponseErrorPanel defaultExpanded error={fetchReleaseError!} title='Fetching JIRA releases' />}
                  {/* Fetch releases loading */}
                  {isFetchingList && <Progress />}
                  {/* All good */}
                  {!isFetchingList && !fetchReleaseError && releases &&
                    <ReleaseListComponent releases={releases} onReleaseSelected={handleReleaseChanged} defaultRelease={releaseId} />
                  }
                </Grid>
                {projectName &&
                  <Grid item xs={6} md={9} lg={10}>
                    {ColumnHeading('Boards')}
                    {!boardTabs?.length &&
                      <Alert variant='outlined' severity="warning">No boards configured</Alert>
                    }
                    {boardTabs?.length &&
                      <>
                        <Tabs value={boardKey} onChange={(_, val) => handleBoardChanged(val)}>
                          {/* Tab header */}
                          {boardTabs.map(x => <Tab key={x.key} label={x.name.toLocaleUpperCase()} value={x.key} />)}
                        </Tabs>
                        {/* Tab content */}
                        {!boardKey &&
                          <Alert variant='outlined' severity="warning">Select a board to start</Alert>
                        }
                        {boardKey &&
                          <>
                            <ReleaseItemsTableComponent release={releases?.find(x => x.id === releaseId)} items={releaseItems || []} loading={isFetchingReleaseItems} />
                            {fetchReleaseItemsError && <ResponseErrorPanel defaultExpanded error={fetchReleaseItemsError!} title='Fetching release items' />}
                          </>
                        }
                      </>
                    }
                  </Grid>
                }
              </Grid>
            </Box>
          }
        </Box>
      }
    </>
  )
};