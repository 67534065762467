import React, { useMemo } from 'react';
import { useEntity, MissingAnnotationEmptyState } from '@backstage/plugin-catalog-react';
import { ErrorBoundary } from '@backstage/core-components';
import { Entity } from '@backstage/catalog-model';
import { ReleaseFetchDataComponent } from './ReleaseFetch';
import { useServiceEntityAnnotations } from '../../hooks/useServiceEntityAnnotations';
import { BIDONE_JIRA_COMPONENT_ANNOTATION, BIDONE_JIRA_BOARD_ANNOTATION } from '../../types'

interface Props {
  entity: Entity;
}

export const ReleaseOverviewComponent: React.FC<Props> = ({ entity }: { entity: Entity }) => {
  const { canShowJira } = useServiceEntityAnnotations(entity)

  const canShow = useMemo(() => canShowJira(), [canShowJira])

  return canShow
    ? <ReleaseFetchDataComponent entity={entity} />
    : <MissingAnnotationEmptyState annotation={[BIDONE_JIRA_BOARD_ANNOTATION, BIDONE_JIRA_COMPONENT_ANNOTATION]} />
};

/** @public */
export const ReleasePage = () => {
  const { entity } = useEntity();
  return (
    <ErrorBoundary>
      <ReleaseOverviewComponent entity={entity} />
    </ErrorBoundary>
  );
};
