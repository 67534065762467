import { Entity } from '@backstage/catalog-model';
import {
  BIDONE_CLOUD_ROLE_NAME_ANNOTATION,
  BIDONE_JIRA_COMPONENT_ANNOTATION,
  BIDONE_JIRA_BOARD_ANNOTATION,
  BIDONE_WEB_APP_NAME_ANNOTATION,
} from '../types';

export const useServiceEntityAnnotations = (entity: Entity) => {
  const webSiteName =
    entity?.metadata.annotations?.[BIDONE_WEB_APP_NAME_ANNOTATION] ?? '';

  const cloudRoleNames = (
    entity?.metadata.annotations?.[BIDONE_CLOUD_ROLE_NAME_ANNOTATION] || ''
  ).split('|');

  const jiraBoardKey =
    entity?.metadata.annotations?.[BIDONE_JIRA_BOARD_ANNOTATION] ?? '';

  const jiraComponentName =
    entity?.metadata.annotations?.[BIDONE_JIRA_COMPONENT_ANNOTATION] ?? '';

  const hasCloudRoleNames = () =>
    !!cloudRoleNames.length && !!cloudRoleNames[0];

  const canShowJira = () => !!jiraBoardKey // component name is optional

  return {
    webSiteName,
    cloudRoleNames,
    hasCloudRoleNames,
    jiraBoardKey,
    jiraComponentName,
    canShowJira
  };
};
