import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';

interface Props {
    name: string
    id: string
}

const getBadgeColour = (id?: string): any => {
    const idNum = Number(id)
    switch (idNum) {
        case 10001: // Done
        case 5: // Resolved
            return ['#00875a', '#ffffff'] // Green
        case 10000: // Todo
            return ['#dfe1e6', '#000000'] // Gray
        default:
            return ['#0052cc', '#ffffff'] // Blue
    }
}

const StatusChip = styled(Chip)((props) => {
    const [backgroundColor, color] = getBadgeColour(props.id)
    return {
        fontWeight: 700,
        backgroundColor,
        color
    }
});

export const IssueStatusBadge: React.FC<Props> = ({ id, name }: Props) => {
    return (
        <StatusChip label={name || id} id={id} />
    )
};