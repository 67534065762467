import React from 'react';
import {
  Table,
  TableColumn,
} from '@backstage/core-components';
import Link from '@material-ui/core/Link';
import { JiraProjectVersion, JiraSearchIssue } from '@internal/backstage-plugin-bidone-backend/src/api/jiraApi';
import { UserLinkComponent } from '../UserLink';
import { IssueStatusBadge } from './IssueStatusBadge';
import { ExternalLinkComponent } from '../ExternalLink';
import { IssuePriorityBadge } from './IssuePriorityBadge';

interface Props {
  loading?: boolean
  release?: JiraProjectVersion
  items: JiraSearchIssue[];
}

interface JiraIssue {
  title: string
  key: string
  id: string
  timetask?: string
  status: any
  assignee?: any
  self: string
  component?: string
  priority?: any
}

export const JiraIssueCustomField = {
  Timetask: 'customfield_10900'
}

const extractIssueLink = (issue: JiraIssue) => {
  const { self, key } = issue || {}
  try {
    const selfUrl = new URL(self)
    return `${selfUrl.origin}/browse/${key}`
  } catch {
    return
  }
}

const extractReleaseLink = (release: JiraProjectVersion) => {
  const { self, projectId, id } = release || {}
  try {
    const selfUrl = new URL(self)
    return `${selfUrl.origin}/projects/${projectId}/versions/${id}`
  } catch {
    return
  }
}

const ReleaseTableTitle = (release?: JiraProjectVersion) => {
  const { name } = release || {}
  return (
    <label>
      Release&nbsp;
      <Link href={extractReleaseLink(release!)} target='_blank'>{name}</Link>&nbsp;
      Jira Tickets Status
    </label>
  )
}

export const ReleaseItemsTableComponent: React.FC<Props> = ({ loading, items, release }: Props) => {
  // Map the details we need
  const issue = items.map(x => {
    const { key, id, fields, self } = x
    return {
      self,
      key,
      id,
      title: fields.summary,
      timetask: fields[JiraIssueCustomField.Timetask],
      status: fields.status,
      assignee: fields.assignee,
      component: fields.components
        ?.map((x: any) => x.name)
        ?.reduce((current: any, next: any) => `${next} ${current}`, ''),
      priority: fields.priority
    } as JiraIssue
  })

  const columns: TableColumn<JiraIssue>[] = [
    {
      title: 'Id', field: 'key', width: '8%',
      render: (issue: JiraIssue) =>
        <Link target='_blank' href={extractIssueLink(issue)}>{issue.key}</Link>
    },
    {
      title: 'Title', field: 'title'
    },
    {
      title: 'Priority', field: 'priority.name', width: '8%',
      render: (issue: JiraIssue) =>
        <IssuePriorityBadge id={issue.priority?.id} name={issue.priority?.name} />
    },
    {
      title: 'Status', field: 'status.name', width: '10%',
      render: (issue: JiraIssue) =>
        <IssueStatusBadge id={issue.status?.id} name={issue.status?.name} />
    },
    {
      title: 'Component(s)', field: 'component', width: '20%'
    },
    {
      title: 'Assignee', field: 'assignee.displayName',
      render: (issue: JiraIssue) =>
        <UserLinkComponent email={issue.assignee?.emailAddress} name={issue.assignee?.displayName} />
    },
    {
      title: 'Timetask', width: '6%',
      render: (issue: JiraIssue) =>
        <ExternalLinkComponent href={issue.timetask} />
    },
  ];

  return (
    <Table
      isLoading={loading}
      title={ReleaseTableTitle(release)}
      options={{ paging: false, thirdSortClick: false }}
      columns={columns}
      data={issue}
    />
  );
};