import React from 'react';
import { ErrorBoundary } from '@backstage/core-components';
import { ReleaseDashboardFetchComponent } from './ReleaseDashboardFetch';

interface Props {
}

// Just a thin wrapper for now, but if we need to apply rendering rule then we can do it here, 
// w/o dictating the logic inside the component
export const ReleaseDashboardComponent: React.FC<Props> = () =>
    <ReleaseDashboardFetchComponent />

// Standard error boundary wrapper
export const ReleaseDashboardPage = () => {
    return (
        <ErrorBoundary>
            <ReleaseDashboardComponent />
        </ErrorBoundary>
    );
};
