import { useQuery } from '@tanstack/react-query';
import { configApiRef, identityApiRef, useApi } from '@backstage/core-plugin-api';
import {
  JiraDashboardSettingsResponse,
  JiraReleaseItemsRequest,
  JiraReleaseItemsResponse,
  JiraReleaseListRequest,
  JiraReleaseListResponse
} from "@internal/backstage-plugin-bidone-backend";
import { handleReponse } from "../helper";
import { UseQueryCustomResult } from "../types";
import { JiraProjectVersion } from '@internal/backstage-plugin-bidone-backend/src/api/jiraApi';

const fetchReleaseList = async (backendUrl: string, payload: JiraReleaseListRequest, accessToken?: string): Promise<JiraReleaseListResponse> => {
  const response = await fetch(`${backendUrl}/api/bidone/jira/releases`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` })
    },
    body: JSON.stringify(payload),
  });

  return handleReponse(response)
}

const fetchReleaseItems = async (backendUrl: string, payload: JiraReleaseItemsRequest, accessToken?: string): Promise<JiraReleaseItemsResponse> => {
  const response = await fetch(`${backendUrl}/api/bidone/jira/release-items`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` })
    },
    body: JSON.stringify(payload),
  });

  return handleReponse(response)
}

const fetchJiraDashboardSettings = async (backendUrl: string, accessToken?: string): Promise<JiraDashboardSettingsResponse> => {
  const response = await fetch(`${backendUrl}/api/bidone/jira/release-dashboard/settings`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` })
    }
  });

  return handleReponse(response)
}

const useQueryJiraReleaseList = (project: string): UseQueryCustomResult<JiraReleaseListResponse> => {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  return useQuery({
    queryKey: ["release-component", "get-releases", project],
    queryFn: async () => {
      if (!project) {
        return []
      }
      const { token } = await identityApi.getCredentials();
      return fetchReleaseList(
        backendUrl,
        {
          project,
          // TODO: Can a selector on UI
          top: 15
        },
        token
      )
    },
  })
}

const useQueryJiraReleaseItem = (
  release?: JiraProjectVersion,
  component?: string
): UseQueryCustomResult<JiraReleaseItemsResponse> => {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const backendUrl = config.getString('backend.baseUrl');
  return useQuery({
    queryKey: ["release-component", "get-release-items", release?.id, component],
    queryFn: async () => {
      if (!release?.id) {
        return []
      }
      const { token } = await identityApi.getCredentials();
      return fetchReleaseItems(
        backendUrl,
        {
          release,
          component: component || ''
        },
        token
      )
    },
  })
}

const useQueryJiraDashboardSettings = (): UseQueryCustomResult<JiraDashboardSettingsResponse> => {
  const config = useApi(configApiRef);
  const identityApi = useApi(identityApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  return useQuery({
    queryKey: ["release-dashboard-component", "settings"],
    queryFn: async () => {
      const { token } = await identityApi.getCredentials();
      return fetchJiraDashboardSettings(
        backendUrl,
        token
      )
    }
  })

}

export {
  useQueryJiraReleaseList,
  useQueryJiraReleaseItem,
  useQueryJiraDashboardSettings
}