import React from 'react';
import Chip from '@mui/material/Chip';
import { styled } from '@mui/system';

interface Props {
    name: string
    id: string
}

const getColour = (id?: string): any => {
    const idNum = Number(id)
    // High-ish
    if (idNum <= 2) {
        return ['#ff0000'] // Red
    }
    // Low-ish
    if (idNum >= 5) {
        return ['#dfe1e6'] // Gray
    }
    // Default
    return ['#666666'] // Black
}

const PriorityChip = styled(Chip)((props) => {
    const [color] = getColour(props.id)
    return {
        backgroundColor: 'transparent',
        borderColor: color,
        color
    }
});

export const IssuePriorityBadge: React.FC<Props> = ({ id, name }: Props) => {
    return (
        <PriorityChip variant='outlined' label={name || id} id={id} />
    )
};