import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Box from '@material-ui/core/Box';
import { AppInsightsOverviewException } from '@internal/backstage-plugin-bidone-backend';
import { MonitoringExceptionPieComponent } from './MonitoringExceptionPieComponent';
import { MonitoringDataList } from '../../types';

export const MonitoringExceptionCard: React.FC<
  MonitoringDataList<AppInsightsOverviewException>
> = props => {
  return (
    <Card>
      <CardHeader title={`Exception Count (Last ${props.dateRanges})`} />
      <CardContent>
        <Box paddingX={2}>
          <MonitoringExceptionPieComponent {...props} />
        </Box>
      </CardContent>
    </Card>
  );
};
