import React, { useMemo, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Table, TableColumn, Link } from '@backstage/core-components';
import { AppInsightsOverviewException } from '@internal/backstage-plugin-bidone-backend';
import { MonitoringDataList } from '../../types';

const excludeColumns = ['appInsightsBladeUrl'];

interface Props extends MonitoringDataList<AppInsightsOverviewException> {
  rowActionsAvail?: boolean
}

export const MonitoringExceptionTableComponent: React.FC<Props> = ({
  list,
  loading,
  dateRanges,
  rowActionsAvail
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [appInsightsBladeUrl, setPppInsightsBladeUrl] = useState<
    string | undefined
  >();
  const showActionMenu = useMemo(() => !!anchorEl, [anchorEl]);

  const columns = useMemo<TableColumn<AppInsightsOverviewException>[]>(() => {
    if (!list?.length) {
      return [];
    }

    const result = Object.keys(list[0]).map<
      TableColumn<AppInsightsOverviewException>
    >(key => {
      const column: TableColumn<AppInsightsOverviewException> = {
        title: key?.toUpperCase(),
        field: key,
        width: '',
        hidden: excludeColumns.indexOf(key) >= 0,
      };

      // Size the columns width by column key
      const keyNormalised = key.toLowerCase();
      if (['region', 'env', 'count'].indexOf(keyNormalised) === 0) {
        column.width = '5%';
      }

      if (['operation_name'].indexOf(keyNormalised) === 0) {
        column.width = '15%';
      }

      return column;
    });

    return result;
  }, [list]);

  const handleActionMenuClick = (
    e: any,
    data?: AppInsightsOverviewException | AppInsightsOverviewException[],
  ) => {
    const exc = Array.isArray(data) ? data[0] : data;
    const { appInsightsBladeUrl } = exc || {};
    setAnchorEl(e.target);
    setPppInsightsBladeUrl(appInsightsBladeUrl);
  };

  return (
    <>
      <Menu
        id="MonitoringExceptionTableComponent_actionMenu"
        MenuListProps={{
          'aria-labelledby': 'MonitoringExceptionTableComponent_actionMenu',
        }}
        anchorEl={anchorEl}
        open={showActionMenu}
        onClose={() => setAnchorEl(null)}
      >
        <>
          {!rowActionsAvail &&
            <MenuItem
              key="appInsightsBladeUrl"
            >
              No actions available
            </MenuItem>
          }
          {rowActionsAvail &&
            <MenuItem
              component="a"
              href={appInsightsBladeUrl}
              target="_blank"
              key="appInsightsBladeUrl"
              onClick={() => setAnchorEl(null)}
            >
              <OpenInNewIcon />
              &nbsp;Open in App Insights
            </MenuItem>
          }
        </>
      </Menu>
      <Table
        title={`Top Exceptions (Last ${dateRanges})`}
        isLoading={loading}
        options={{ paging: false, thirdSortClick: false }}
        columns={columns}
        data={list}
        actions={[
          () => ({
            icon: () => <MoreVertIcon />,
            onClick: handleActionMenuClick,
          }),
        ]}
      />
    </>
  );
};
