import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  svg: {
    width: 'auto',
    height: 40,
  },
  path: {
    fill: '#7df3e1',
  },
});

const LogoIcon = () => {
  const classes = useStyles();

  return (
    <svg viewBox="140 0 250 260" className={classes.svg} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill="#00A2E0" fillRule="evenodd" d="M 321.032 120.052 C 318.168 114.649 313.809 109.639 308.08 105.072 C 301.666 100.013 294.442 96.329 286.471 94.07 C 281.302 92.596 275.884 91.761 270.092 91.516 C 268.909 91.467 267.726 91.418 266.481 91.418 C 250.352 91.418 236.34 95.985 224.882 105.023 C 213.485 114.011 207.693 125.062 207.693 137.783 C 207.693 150.553 213.485 161.604 224.882 170.593 C 225.379 170.985 225.816 171.329 226.313 171.673 C 230.548 174.816 235.157 177.371 240.077 179.335 C 248.047 182.528 256.89 184.148 266.481 184.148 C 275.074 184.148 283.046 182.823 290.332 180.268 C 295.376 178.5 300.108 176.143 304.467 173.146 C 305.651 172.311 306.897 171.476 308.017 170.543 C 319.476 161.506 325.267 150.455 325.267 137.734 C 325.33 131.447 323.897 125.455 321.032 120.052 Z M 296.185 161.309 C 288.027 167.744 278.063 170.985 266.481 170.985 C 254.897 170.985 244.933 167.744 236.776 161.309 C 228.617 154.875 224.507 147.017 224.507 137.881 C 224.507 133.264 225.504 128.942 227.559 125.16 C 229.552 121.329 232.666 117.793 236.838 114.502 C 240.948 111.261 245.494 108.804 250.352 107.184 C 255.209 105.563 260.627 104.777 266.542 104.777 C 278.063 104.777 288.027 108.068 296.247 114.502 C 304.406 120.936 308.516 128.795 308.516 137.881 C 308.516 147.017 304.343 154.875 296.185 161.309 Z" clipRule="evenodd" />
      <path fill="#B0D7F4" fillRule="evenodd" d="M 191.565 200.308 C 186.147 197.803 179.857 196.329 173.132 196.329 C 153.142 196.329 136.887 209.099 136.887 224.914 C 136.887 240.681 153.079 253.5 173.132 253.5 C 193.122 253.5 209.375 240.73 209.375 224.914 C 209.375 219.217 207.257 213.913 203.583 209.443 L 240.077 179.483 C 235.157 177.518 230.548 174.964 226.313 171.82 L 191.565 200.308 Z M 339.652 197.704 C 336.352 197.704 333.238 198.196 330.312 199.031 L 304.406 173.294 C 300.047 176.241 295.313 178.648 290.269 180.416 L 317.172 207.184 C 314.307 210.573 312.563 214.649 312.563 219.07 C 312.563 230.858 324.645 240.386 339.591 240.386 C 354.537 240.386 366.617 230.858 366.617 219.07 C 366.68 207.282 354.598 197.704 339.652 197.704 Z M 295.189 50.701 C 310.446 49.571 322.341 39.453 322.341 27.174 C 322.341 14.109 308.889 3.5 292.324 3.5 C 275.759 3.5 262.308 14.109 262.308 27.174 C 262.308 36.408 269.034 44.364 278.748 48.244 L 270.03 91.565 C 275.759 91.811 281.239 92.646 286.408 94.119 L 295.189 50.701 Z" clipRule="evenodd" />
    </svg>
  );
};

export default LogoIcon;
