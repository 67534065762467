import React from 'react';
import {
  Progress,
  ResponseErrorPanel
} from '@backstage/core-components';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { Entity } from '@backstage/catalog-model';
import { Grid } from '@material-ui/core';
// @ts-ignore
import useHashParam from 'use-hash-param'
import { ReleaseListComponent } from './ReleaseList';
import { useServiceEntityAnnotations } from '../../hooks/useServiceEntityAnnotations';
import { ReleaseItemsTableComponent } from './ReleaseItemsTable';
import { useQueryJiraReleaseItem, useQueryJiraReleaseList } from '../../hooks/useQueryJira';

export const ReleaseFetchDataComponent = ({ entity }: { entity: Entity }) => {
  const { jiraBoardKey: project, jiraComponentName: component } = useServiceEntityAnnotations(entity)

  // The selected release
  const [releaseId, setReleaseId] = useHashParam<string>('id')

  // Fetch releases list query
  const { data: releases, isFetching: isFetchingList, error: fetchReleaseError } =
    useQueryJiraReleaseList(project)

  // Fetch release items query
  const { data: releaseItems, isFetching: isFetchingReleaseItems, error: fetchReleaseItemsError } =
    useQueryJiraReleaseItem(releases?.find(x => x.id === releaseId), component)

  return (
    <>
      {fetchReleaseError && <ResponseErrorPanel defaultExpanded error={fetchReleaseError!} title='Fetching JIRA releases' />}
      {isFetchingList && <Progress />}
      {!isFetchingList && !fetchReleaseError &&
        <Grid container spacing={2}>
          <Grid item xs={6} md={3} lg={2}>
            <Card>
              <CardContent>
                <ReleaseListComponent releases={releases || []} onReleaseSelected={setReleaseId} defaultRelease={releaseId} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={6} md={9} lg={10}>
            <Card>
              <CardContent>
                <>
                  <ReleaseItemsTableComponent release={releases?.find(x => x.id === releaseId)} items={releaseItems || []} loading={isFetchingReleaseItems} />
                  {fetchReleaseItemsError && <ResponseErrorPanel defaultExpanded error={fetchReleaseItemsError!} title='Fetching release items' />}
                </>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      }
    </>
  )
};


