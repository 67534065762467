import React from 'react';
import { Entity } from '@backstage/catalog-model';
// import { useSites } from '../../hooks/useSites';
import { ErrorBoundary, Progress, ResponseErrorPanel } from '@backstage/core-components';
import { useServiceEntityAnnotations } from '../../hooks/useServiceEntityAnnotations';
// import { BIDONE_WEB_APP_NAME_ANNOTATION } from '@internal/backstage-plugin-bidone-backend';
import {
  useEntity,
  MissingAnnotationEmptyState,
} from '@backstage/plugin-catalog-react';
import { AzureOverviewTable } from '../AzureOverviewTableComponent/AzureOverviewTable';
import { configApiRef, identityApiRef, microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/esm/useAsync';

/** @public */
export const isAzureWebSiteNameAvailable = (entity: Entity) =>
  entity?.metadata.annotations?.["bidone/web-apps"];

const AzureSitesOverview = ({ entity }: { entity: Entity }) => {
   const webSiteName  =  entity?.metadata.annotations?.["bidone/web-apps"];

  // const [sites] = useSites({
  //   name: webSiteName,
  // });

  const config = useApi(configApiRef);
  const oauthApi = useApi(microsoftAuthApiRef);
  const identityApi = useApi(identityApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  const { value, loading, error } = useAsync(async (): Promise<any> => {
    const msaccessToken = await oauthApi.getAccessToken(
      'https://management.azure.com/.default',
    );

    const { token: accessToken } = await identityApi.getCredentials();

    let response: Response  = {} as Response;
    let retryCount = 0;
    const maxRetries = 5;

    while (retryCount < maxRetries) {
      try {
        response = await fetch(
          `${backendUrl}/api/bidone/azure/list/${webSiteName}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
              mstoken: `${msaccessToken}`,
            },
          },
        );

        if (response.ok) {
          break;
        }
      } catch (e) {
        console.error(`Error occurred during fetch: ${e}`);
      }

      retryCount++;
    }

    // if (!response.ok) {
    //   throw new Error('Failed to fetch data');
    // }

    return await response.json();
  }, [oauthApi, identityApi, backendUrl]);

  if (loading) {
    return <Progress />;
  } else if (error) {
    return <ResponseErrorPanel error={error} />;
  }
  // if (sites.error) {
  //   return (
  //     <div>
  //       <ResponseErrorPanel error={sites.error} />
  //     </div>
  //   );
  // }

  return (
    <AzureOverviewTable
      data={value?.items ?? []}
      loading={loading}
    />
  );
};

/** @public */
export const AzureOverviewWidget = () => {
  const { entity } = useEntity();

  // if (!isAzureWebSiteNameAvailable(entity)) {
  //   return (
  //     <MissingAnnotationEmptyState
  //       annotation={BIDONE_WEB_APP_NAME_ANNOTATION}
  //     />
  //   );
  // }

  return (
    <ErrorBoundary>
      <AzureSitesOverview entity={entity} />
    </ErrorBoundary>
  );
};