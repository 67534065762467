import React, { useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import isEqual from 'lodash/isEqual';
import random from 'lodash/random';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import { Entity } from '@backstage/catalog-model';
import {
  useEntity,
  MissingAnnotationEmptyState,
} from '@backstage/plugin-catalog-react';
import { ErrorBoundary, ResponseErrorPanel } from '@backstage/core-components';
import {
  configApiRef,
  identityApiRef,
  microsoftAuthApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import {
  AppInsightsOverviewException,
  AppInsightsOverviewRequest,
  AppInsightsOverviewResponse,
  AppInsightsPerformance,
} from '@internal/backstage-plugin-bidone-backend';

import { useServiceEntityAnnotations } from '../../hooks/useServiceEntityAnnotations';
import { MonitoringExceptionTableComponent } from './MonitoringExceptionTableComponent';
import { MultiSelect } from '../MultiSelect';
import { BIDONE_CLOUD_ROLE_NAME_ANNOTATION } from '../../types';
import { MonitoringPerfCard } from './MonitoringPerfCard';
import { MonitoringExceptionCard } from './MonitoringExceptionCard';
import { handleReponse } from '../../helper';
import { timeRanges } from '../../types';

interface Props {
  entity: Entity;
}

const ALL_VALUE = 'ALL';

const fetchAppInsights = async (backendUrl: string, payload: AppInsightsOverviewRequest, accessToken?: string, msToken?: string): Promise<AppInsightsOverviewResponse> => {
  const response = await fetch(`${backendUrl}/api/bidone/monitoring/overview`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
      mstoken: `${msToken}`,
    },
    body: JSON.stringify(payload),
  });

  return handleReponse(response)
}

export const MonitoringOverviewFetch: React.FC<Props> = ({ entity }) => {
  const { cloudRoleNames, hasCloudRoleNames } =
    useServiceEntityAnnotations(entity);
  const config = useApi(configApiRef);
  const oauthApi = useApi(microsoftAuthApiRef);
  const identityApi = useApi(identityApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  const [regionFilter, setRegionFilter] = useState<string[]>([]);
  const [envFilter, setEnvFilter] = useState<string[]>([]);
  const [dateFilter, setDateFilter] = useState<string>('7d');

  const canShow = hasCloudRoleNames();

  // Fetch App Insights query
  const { data: value, isFetching, error } = useQuery({
    queryKey: ["monitoring-component", "get-app-insights", cloudRoleNames.join('|'), dateFilter, canShow],
    queryFn: async () => {
      if (!canShow) {
        return { exceptions: [], perfs: [] };
      }
      const { token } = await identityApi.getCredentials();
      const msaccessToken = await oauthApi.getAccessToken(
        'https://api.applicationinsights.io/.default',
      );
      return fetchAppInsights(
        backendUrl,
        {
          cloudRoleNames,
          dateRange: dateFilter,
          top: 20, // TODO: Can have an UI input for this
        },
        token,
        msaccessToken
      )
    }
  })

  // Filter controls
  const regions = useMemo<string[]>(() => {
    if (!value?.exceptions?.length) {
      return [];
    }
    return [...new Set(value.exceptions.map(x => x.region))].sort();
  }, [value]);

  const envs = useMemo<string[]>(() => {
    if (!value?.exceptions?.length) {
      return [];
    }
    return [...new Set(value.exceptions.map(x => x.env))].sort();
  }, [value]);

  // For sorting, filtering etc
  const rows = useMemo<AppInsightsOverviewException[]>(() => {
    if (!value?.exceptions?.length) {
      return [];
    }
    let result: AppInsightsOverviewException[] = [];

    // Total aggregation
    if (!envFilter?.length && !regionFilter?.length) {
      [...value.exceptions].forEach(exc => {
        const existing = result.findIndex(x =>
          isEqual(
            { ...x, region: ALL_VALUE, env: ALL_VALUE },
            { ...exc, region: ALL_VALUE, env: ALL_VALUE },
          ),
        );
        if (existing >= 0) {
          result[existing].count += exc.count;
        } else {
          result.push({
            ...exc,
            region: ALL_VALUE,
            env: ALL_VALUE,
          });
        }
      });
      // All envs
    } else if (envFilter?.length && !regionFilter?.length) {
      [...value.exceptions]
        .filter(x => envFilter.indexOf(x.env) >= 0)
        .forEach(exc => {
          const existing = result.findIndex(x =>
            isEqual({ ...x, region: ALL_VALUE }, { ...exc, region: ALL_VALUE }),
          );
          if (existing >= 0) {
            result[existing].count += exc.count;
          } else {
            result.push({
              ...exc,
              region: ALL_VALUE,
            });
          }
        });
      // All sites
    } else if (!envFilter?.length && regionFilter?.length) {
      [...value.exceptions]
        .filter(x => regionFilter.indexOf(x.region) >= 0)
        .forEach(exc => {
          const existing = result.findIndex(x =>
            isEqual({ ...x, env: ALL_VALUE }, { ...exc, env: ALL_VALUE }),
          );
          if (existing >= 0) {
            result[existing].count += exc.count;
          } else {
            result.push({
              ...exc,
              env: ALL_VALUE,
            });
          }
        });
      // Some envs & some sites
    } else {
      result = [...value.exceptions].filter(
        x =>
          envFilter.indexOf(x.env) >= 0 && regionFilter.indexOf(x.region) >= 0,
      );
    }

    // TODO: Hard code sorting count for PoC
    return result.sort((x, y) => {
      if (x.count === y.count) return 0;
      return x.count > y.count ? -1 : 1;
    });
  }, [value, regionFilter, envFilter]);

  const perfs = useMemo<AppInsightsPerformance[]>(() => {
    // Hard code for demo
    const perf = {
      env: '',
      region: '',
    };
    const perfs = [
      {
        ...perf,
        operation_Name: 'GET /some-api',
        median: random(300, 700),
        p80: random(3000, 7000),
        p95: random(6000, 15000),
      },
      {
        ...perf,
        operation_Name: 'POST /another-long-name-api',
        median: random(300, 700),
        p80: random(3000, 7000),
        p95: random(6000, 15000),
      },
      {
        ...perf,
        operation_Name: 'GET /some-api-some-api?some-api=some-api',
        median: random(300, 700),
        p80: random(3000, 7000),
        p95: random(6000, 15000),
      },
      {
        ...perf,
        operation_Name: 'GET /some-api/some-api/some-api',
        median: random(300, 700),
        p80: random(3000, 7000),
        p95: random(6000, 15000),
      },
      {
        ...perf,
        operation_Name: 'GET /some-api',
        median: random(300, 700),
        p80: random(3000, 7000),
        p95: random(6000, 15000),
      },
    ];

    return perfs.sort((x, y) => ((x.p95 || 0) >= (y.p95 || 0) ? -1 : 1));
  }, []);

  const dateRangeLabel = useMemo(() => timeRanges[dateFilter] || '#', [dateFilter])
  // We can only take row actions if it's not ALL aggegated view
  const tableActionsAvail = useMemo(() => envFilter.length > 0 && regionFilter.length > 0, [envFilter, regionFilter])

  if (error) {
    return <ResponseErrorPanel error={error} />;
  }

  return (
    <>
      {!canShow && (
        <MissingAnnotationEmptyState
          annotation={BIDONE_CLOUD_ROLE_NAME_ANNOTATION}
        />
      )}
      {canShow && (
        <>
          <Grid container padding={2}>
            <Grid item sm={12} md={5} paddingRight={2} paddingBottom={2}>
              <Card>
                <CardContent>
                  <MultiSelect
                    label="Environments"
                    defaultVal={['PROD']}
                    valueList={envs}
                    onChange={setEnvFilter}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={5} paddingRight={2} paddingBottom={2}>
              <Card>
                <CardContent>
                  <MultiSelect
                    label="Regions"
                    defaultVal={regions}
                    valueList={regions}
                    onChange={setRegionFilter}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item sm={12} md={2} paddingRight={2} paddingBottom={2}>
              <Card>
                <CardContent>
                  <InputLabel id="date-select-label">Date range</InputLabel>
                  <Select
                    labelId="date-select-label"
                    id="date-select"
                    value={dateFilter}
                    label="Date range"
                    onChange={evt => {
                      setDateFilter(evt.target.value as string);
                    }}
                  >
                    {Object.keys(timeRanges).map(d => (
                      <MenuItem value={d}>{timeRanges[d]}</MenuItem>
                    ))}
                  </Select>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid container paddingLeft={2} paddingRight={2} paddingBottom={2}>
            {/* <Grid item sm={12} md={6} paddingRight={2} paddingBottom={2}>
              <MonitoringPerfCard list={perfs} loading={isFetching} dateRanges={dateRangeLabel} />
            </Grid> */}
            <Grid item sm={12} md={12} paddingRight={2} paddingBottom={2}>
              <MonitoringExceptionCard list={rows} dateRanges={dateRangeLabel} />
            </Grid>
          </Grid>
          <MonitoringExceptionTableComponent list={rows} loading={isFetching} dateRanges={dateRangeLabel} rowActionsAvail={tableActionsAvail} />
        </>
      )}
    </>
  );
};

/** @public */
export const MonitoringPage = () => {
  const { entity } = useEntity();

  return (
    <ErrorBoundary>
      <MonitoringOverviewFetch entity={entity} />
    </ErrorBoundary>
  );
};
