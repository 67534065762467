import {
    CatalogKindExploreContent,
    DomainExplorerContent,
    ExploreLayout,
    GroupsExplorerContent,
  } from '@backstage-community/plugin-explore';
  import React from 'react';
//   import { InnerSourceExploreContent } from './InnerSourceExploreContent';
  
  export const ExplorePage = () => {
    return (
      <ExploreLayout
        title="Bidone"
        subtitle="Explore available components and services"
      >
        <ExploreLayout.Route path="domains" title="Domains">
            <DomainExplorerContent />
        </ExploreLayout.Route>
        <ExploreLayout.Route path="systems" title="Systems">
          <CatalogKindExploreContent kind="system" />
        </ExploreLayout.Route>
        <ExploreLayout.Route path="groups" title="Teams">
            <GroupsExplorerContent />
        </ExploreLayout.Route>
        {/* <ExploreLayout.Route path="users" title="Users">
          <CatalogKindExploreContent kind="user" />
        </ExploreLayout.Route> */}
        {/* <ExploreLayout.Route path="inner-source" title="InnerSource">
          <InnerSourceExploreContent />
        </ExploreLayout.Route> */}
      </ExploreLayout>
    );
  };

  export const explorePage = <ExplorePage />;