import { useQuery } from '@tanstack/react-query';
import { configApiRef, IdentityApi, identityApiRef, microsoftAuthApiRef, useApi } from '@backstage/core-plugin-api';
import { AvailabilityStatus, DatabaseAvailability, ServiceAvailability, ServiceAvailabilityStatus } from '@internal/backstage-plugin-bidone-backend';
import { UseQueryCustomResult } from '../types';
import { handleReponse } from '../helper';

const SERVICE_AVAILABILITY_ENDPOINT = '/api/bidone/service/availability';
const BIDONE_CLOUD_RESOURCE_ENDPOINT = '/api/bidone/azure/resources';


const pingService = async (
    backendUrl: string,
    service: string,
    type: string,
    kind: string,
    env: ServiceAvailability,
  ) => {
    if (!env.address) {
      throw new Error('Address is missing');
    }
  
    const response = await fetch(
      `${backendUrl}${SERVICE_AVAILABILITY_ENDPOINT}/${service}?address=${env.address}&env=${env.key}&type=${type}&kind=${kind}`,
    );
    const services = (await response.json()) as ServiceAvailability;
    return services;
  };


  const fetchServiceAvailabilityResult = async (backendUrl: string, services : ServiceAvailabilityStatus[], accessToken?: string) : Promise<ServiceAvailabilityStatus[]> => {
    const response = await fetch(`${backendUrl}/api/bidone/monitoring/service/availability`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` })
      },
      body: JSON.stringify(services),
    });
  
    return handleReponse(response)
  };
  
  const fetchCloudResources = async (
    backendUrl: string,
    oauthApi: any,
    identityApi: IdentityApi,
    queryType: string
  ): Promise<ServiceAvailabilityStatus[]> => {
        const { token: accessToken } = await identityApi.getCredentials();
        const msaccessToken = await oauthApi.getAccessToken(
          'https://management.azure.com/.default',
        );
  
        const response = await fetch(
          `${backendUrl}${BIDONE_CLOUD_RESOURCE_ENDPOINT}/${queryType}`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
              mstoken: `${msaccessToken}`
            },
          },
        );
  
        if (response.ok) {
          return await response.json();
        } 

        return handleReponse(response);
  };

  const fetchDatabaseAvailabilityResult = async (backendUrl: string, accessToken?: string, msToken?: string): Promise<DatabaseAvailability[]> => {
    const response = await fetch(`${backendUrl}/api/bidone/monitoring/database/availability`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        ...(accessToken && { Authorization: `Bearer ${accessToken}` }),
        mstoken: `${msToken}`,
      }
    });
  
    return handleReponse(response)
  }


  const useQueryCheckServiceAvailability = (service: string, type: string, kind: string, env: ServiceAvailability) : UseQueryCustomResult<ServiceAvailability> => {
    const config = useApi(configApiRef);
    const identityApi = useApi(identityApiRef);
    const backendUrl = config.getString('backend.baseUrl');

        return  useQuery({
        queryKey: ['service-availability', service, env],
        queryFn: async () => {
          return pingService(backendUrl, service, type, kind, env);
        },
      });
  };

  const useQueryCloudResourceList = () : UseQueryCustomResult<ServiceAvailabilityStatus[]> => {
    const config = useApi(configApiRef);
    const oauthApi = useApi(microsoftAuthApiRef);
    const identityApi = useApi(identityApiRef);
    const backendUrl = config.getString('backend.baseUrl');
    const queryType = 'service';

    return useQuery({
      queryKey: ["cloud-resources"],
      refetchInterval: 900000, //15 mins
      retry: 3,
      retryDelay: 5000,
      refetchIntervalInBackground: false,
      queryFn: async () => {
        return fetchCloudResources(backendUrl, oauthApi, identityApi, queryType);
      },
    });
  };

  const useQueryDBResourceList = () : UseQueryCustomResult<ServiceAvailabilityStatus[]> => {
    const config = useApi(configApiRef);
    const oauthApi = useApi(microsoftAuthApiRef);
    const identityApi = useApi(identityApiRef);
    const backendUrl = config.getString('backend.baseUrl');
    const queryType = 'database';

    return useQuery({
      queryKey: ["db-resources"],
      refetchInterval: 900000, //15 mins
      retry: 3,
      retryDelay: 5000,
      refetchIntervalInBackground: false,
      queryFn: async () => {
        return fetchCloudResources(backendUrl, oauthApi, identityApi, queryType);
      },
    });
  };

  const useQueryDatabaseAvailabilityStatus = (
    dbResourceList?: ServiceAvailabilityStatus[],
  ): UseQueryCustomResult<ServiceAvailabilityStatus[]> => {
    const config = useApi(configApiRef);
    const oauthApi = useApi(microsoftAuthApiRef);
    const identityApi = useApi(identityApiRef);
    const backendUrl = config.getString('backend.baseUrl');

    return useQuery({
      queryKey: ['database-availability-status'],
      refetchIntervalInBackground: false,
      refetchInterval: 900000, //15 mins
      retry: 3,
      retryDelay: 5000,
      enabled: dbResourceList && dbResourceList.length > 0,
      queryFn: async () => {
        const { token: accessToken } = await identityApi.getCredentials();
        const msaccessToken = await oauthApi.getAccessToken(
          'https://api.applicationinsights.io/.default',
        );

        const response = await fetchDatabaseAvailabilityResult(
          backendUrl,
          accessToken,
          msaccessToken,
        );

        if (response && response.length > 0 && dbResourceList && dbResourceList.length > 0) {
          dbResourceList.map(resource => {
            const dbResource = response.find(
              dbResource =>
                dbResource.dbName === resource.name &&
                dbResource.site?.trim() === resource.kind,
            );
          resource.environments.map((env : ServiceAvailability) => {
            const envResult = response?.find((item: any) => item?.dbName === resource.name && item.site?.trim() === resource.kind&& item?.environment === env.key && item?.location === env.location) as DatabaseAvailability;
            if (envResult) {
              env.isAvailable = false;
              env.status = 2;
            }
              });
          });
        }
        return dbResourceList;
      },
    });
  };



  const useQueryServiceAvailabilityStatus = (
    cloudResourceList: ServiceAvailabilityStatus[],
  ): UseQueryCustomResult<ServiceAvailabilityStatus[]> => {
    const config = useApi(configApiRef);
    const identityApi = useApi(identityApiRef);
    const backendUrl = config.getString('backend.baseUrl');

    const BATCH_SIZE = 4; // Define the batch size

    // Utility function to split array into batches
  const splitIntoBatches = (array : any, batchSize : number) => {
    const batches = [];
    for (let i = 0; i < array.length; i += batchSize) {
      batches.push(array.slice(i, i + batchSize));
    }
    return batches;
  };

    return useQuery({
      queryKey: ['cloud-service-availability-status'],
      refetchIntervalInBackground: false,
      refetchInterval: 900000, //15 mins
      retry: 3,
      retryDelay: 5000,
      enabled: cloudResourceList && cloudResourceList.length > 0,  
      queryFn: async () => {
        const { token: accessToken } = await identityApi.getCredentials();

        const batches = splitIntoBatches(cloudResourceList, BATCH_SIZE);

        const results = await Promise.all(
          batches.map(batch =>
            fetchServiceAvailabilityResult(backendUrl, batch, accessToken)
          )
        );
  
        // Combine results from all batches
        return results.flat();
      },
    });
  };


  export {
    useQueryCheckServiceAvailability,
    useQueryCloudResourceList,
    useQueryDBResourceList,
    useQueryDatabaseAvailabilityStatus,
    useQueryServiceAvailabilityStatus
  }
