export const BIDONE_WEB_APP_NAME_ANNOTATION = 'bidone/web-apps';
export const BIDONE_CLOUD_ROLE_NAME_ANNOTATION = 'bidone/cloud-role-names';
export const BIDONE_JIRA_BOARD_ANNOTATION = 'bidone/jira-board';
export const BIDONE_JIRA_COMPONENT_ANNOTATION = 'bidone/jira-component';

export interface MonitoringDataList<T> {
    list: T[];
    loading?: boolean;
    dateRanges: string
}

export interface UseQueryCustomResult<T> {
    data?: T
    isFetching: boolean
    error: Error | null
}

export const timeRanges: { [key: string]: string } = {
    '3d': '3 days',
    '7d': '7 days',
    '14d': '14 days',
    '30d': '30 days',
};