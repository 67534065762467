import React from 'react';
import Link from '@material-ui/core/Link';
import Tooltip from '@mui/material/Tooltip';
import LaunchIcon from '@mui/icons-material/Launch';

interface Props {
  href?: string
}

export const ExternalLinkComponent: React.FC<Props> = ({ href }: Props) => {
  return (href &&
    <Tooltip title={href} placement='left'>
      <Link target='_blank' href={href || '#'}>
        <LaunchIcon />
      </Link>
    </Tooltip>
  )
};