import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import {
  InfoCard,
  Header,
  Page,
  Content,
  ContentHeader,
  HeaderLabel,
  SupportButton,
  ErrorBoundary,
} from '@backstage/core-components';
import { PullRequestFetchComponent } from '../PullRequestFetchComponent/PullRequestFetchComponent'; // Add this line
import {
    useEntity,
    MissingAnnotationEmptyState,
  } from '@backstage/plugin-catalog-react';
  import { Entity } from '@backstage/catalog-model';

export const PullRequestComponent =  ({ entity }: { entity: Entity })  => {
  return (
  <Page themeId="tool">
    <Content>
        <Grid item container spacing={3} direction="column">
            <PullRequestFetchComponent entity={entity} /> {/* Update the component name */}
            </Grid>
    </Content>
  </Page>
)
};

/** @public */
export const PullRequestWidget = () => {
    const { entity } = useEntity();
  // if (!isAzureWebSiteNameAvailable(entity)) {
  //   return (
  //     <MissingAnnotationEmptyState
  //       annotation={BIDONE_WEB_APP_NAME_ANNOTATION}
  //     />
  //   );
  // }

  return (
    <ErrorBoundary>
      <PullRequestComponent entity={entity}/>
    </ErrorBoundary>
  );
};


