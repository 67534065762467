import React, { useState } from 'react';
import { Grid, Typography, Box, Divider } from '@material-ui/core';
import { ErrorBoundary } from '@backstage/core-components';
import { Tabs, Tab } from '@material-ui/core';
import { ServiceAvailabilityList } from './ServiceAvailabilityTable';
import { MonitorHeartIcon } from '../../../../../packages/app/src/components/Root/MonitorHeartIcon';
import { DatabaseAvailabilityList } from './DatabaseAvailabilityTable';

 const PageHeading = (heading: string) => <Typography variant='h1' style={{fontSize: 24, marginBottom: 0}} >{heading}</Typography>

export const ServiceHealthPageStatus  = ({}) => {
    const [tabValue, setTabValue] = useState<string>('database');

    return (
      <Box padding={2}>
         <Box display="flex" alignItems="center">
            <MonitorHeartIcon style={{ fontSize: 26, color: '#0052CC' }} />
            <Box mr={1} />
            {PageHeading('Bidone Resource Health')}
            <Divider/>            
          </Box>

        <Box paddingY={2}>
          <Grid container spacing={2}>
              <Grid item xs={12} md={12} lg={12}>
                  <>
                    <Tabs value={tabValue} onChange={(_, newValue) => setTabValue(newValue)}>
                      {/* Tab header */}
                     {/* <Tab key='service-health' label='Services' value='service-health' /> */}
                     <Tab key='database' label='Databases' value='database' />
                    </Tabs> 
                    <Divider/>
                    {/* Tab content */}
                    {/* {tabValue === 'service-health' && <ServiceAvailabilityList />} */}
                    {tabValue === 'database' &&  <DatabaseAvailabilityList/>}
                  </>                   
              </Grid>
          </Grid>
        </Box>
    </Box>
    );
};

/** @public */
export const ServiceHealthPage = () => {
  
    return (
      <ErrorBoundary>
        <ServiceHealthPageStatus />
      </ErrorBoundary>
    );
  };