import React, { useMemo } from 'react';
import { PieChart } from '@mui/x-charts/PieChart';
import { AppInsightsOverviewException } from '@internal/backstage-plugin-bidone-backend';
import { PieValueType } from '@mui/x-charts/models';
import { MakeOptional } from '@mui/x-charts/models/helpers';
import Box from '@material-ui/core/Box';
import Grid from '@mui/material/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { styled } from '@mui/material/styles';
import { MonitoringDataList } from '../../types';

interface ChartData {
  [key: string]: MakeOptional<PieValueType, 'id'>[];
}

const ChartTitle = styled(FormLabel)(() => ({
  fontWeight: 'bold !important',
  display: 'block',
  textAlign: 'center',
  padding: '10px !important',
}));

// TODO: Hard code for now
const regionColors: { [key: string]: string } = {
  _DEFAULT_: '#b0d7f4', // BidOne light blue
  WEU: '#172b4d', // dark teal
  AUE: '#575b7d', // dark purple
  BRS: '#2ca02c', // dark green,
  SAF: '#ff7f0e', // orange
}

export const MonitoringExceptionPieComponent: React.FC<
  MonitoringDataList<AppInsightsOverviewException>
> = ({ list, loading, dateRanges }) => {
  const chartData = useMemo<ChartData>(() => {
    const result: ChartData = {};
    list.forEach(({ env, region, count }) => {
      if (!result[env]) {
        result[env] = [];
      }
      const existing = result[env].findIndex(x => x.id === region);
      if (existing >= 0) {
        result[env][existing].value += count;
      } else {
        result[env].push({
          value: count,
          label: `${region} (${count.toLocaleString()})`,
          id: region,
          color: regionColors[region] || regionColors._DEFAULT_,
        });
      }
    });

    // Sort the regions in place
    Object.keys(result).forEach(chart => {
      result[chart].sort((x, y) =>
        (x.label?.toString() || '').localeCompare(y.label?.toString() || ''),
      );
    });

    return result;
  }, [list]);

  const chartCount = useMemo(() => Object.keys(chartData).length, [chartData]);

  return (
    <Grid container>
      {Object.keys(chartData).map(chart => (
        <Grid item sm={12} md={12 / chartCount}>
          <Box>
            <ChartTitle>{`${chart} ENV`}</ChartTitle>
            <PieChart
              loading={loading}
              series={[
                {
                  data: chartData[chart],
                  highlightScope: { faded: 'global', highlighted: 'item' },
                  faded: {
                    innerRadius: 20,
                    additionalRadius: -20,
                    color: 'gray',
                  },
                },
              ]}
              height={300}
              label={chart}
            />
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};
