const handleReponse = async (response: Response) => {
  let result: any
  try {
    result = await response.json();
  } catch {
    result = `${response.status} - ${response.statusText} - ${response.url}`
  }

  if (!response.ok) {
    throw new Error(JSON.stringify(result, null, 2));
  }

  return result
}

export { handleReponse }