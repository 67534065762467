import React, { useCallback, useEffect, useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

interface Props {
  label: string;
  valueList: string[];
  defaultVal?: string[];
  onChange?: (value: string[]) => void;
}

export const MultiSelect: React.FC<Props> = ({
  label,
  valueList,
  onChange,
  defaultVal,
}) => {
  const [val, setVal] = useState<string[]>(defaultVal || []);

  const triggerChangeEvent = useCallback(
    (val?: string[]) => {
      if (onChange && val) {
        onChange(val);
      }
    },
    [onChange],
  );

  // Trigger the default value event
  useEffect(() => {
    triggerChangeEvent(defaultVal);
  }, []);

  const handleChange = (event: SelectChangeEvent<typeof val>) => {
    const {
      target: { value },
    } = event;
    const nextVal = typeof value === 'string' ? value.split(',') : value;
    setVal(nextVal);
    triggerChangeEvent(nextVal);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id={`${label}-label`}>{label}</InputLabel>
      <Select
        labelId={`${label}-label`}
        id={`${label}-multiple-checkbox`}
        multiple
        value={val}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={selected => selected.join(', ')}
      >
        {valueList.map(v => (
          <MenuItem key={v} value={v}>
            <Checkbox checked={val.indexOf(v) > -1} />
            <ListItemText primary={v} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
