import React, { useMemo } from 'react';
import {
  Table,
  TableColumn,
  Progress,
  ResponseErrorPanel,
  StatusError,
  StatusOK,
  StatusAborted,
} from '@backstage/core-components';
import {
  ServiceAvailability,
  ServiceAvailabilityStatus,
} from '@internal/backstage-plugin-bidone-backend';
import {
  configApiRef,
  useApi,
} from '@backstage/core-plugin-api';
import { Box, Chip} from '@material-ui/core';
import { useQueryDatabaseAvailabilityStatus, useQueryDBResourceList } from '../../hooks';



const Service = ({
  value,
}:
{
  value: string;
}) => {
  return (
    <div>
      <div style={{ width: '200px' }}>{value}</div>
    </div>
  );
};

const Kind = ({ kind }: { kind: string }) => {
  return (
    <div>
      <Chip
        key={kind}
        label={`${kind}`}
        size="small"
        variant="default"
        style={{ marginBottom: '1px' }}
      />
    </div>
  );
};

const Team = ({ value }: { value: string }) => {
  return (
    <div>
      <Chip
        key={value}
        label={`${value}`}
        size="small"
        variant="outlined"
        style={{ marginBottom: '1px', color: '#0052CC' }}
      />
    </div>
  );
};

const Status = ({
  env,
  kind,
  service,
  type,
}: {
  service: string;
  kind: string;
  type: string;
  env: ServiceAvailability;
}) => {
  const config = useApi(configApiRef);
  const backendUrl = config.getString('backend.baseUrl');

  if (!env) {
    return <StatusAborted />;
  }
  else if (env && env.status === 1) {
      return <StatusOK />;
    }
    else if (env && env.status === 2
      ) {
        return <StatusError />;
      } else {
        return <StatusAborted />;
      }
};

const generateColumns = (services: ServiceAvailabilityStatus[]) => {
  if (!services) {
    return [];
  }
  return services.map(serviceItem => {
    const devEnv = serviceItem.environments.find(env => env.key === 'dev');
    const qaEnv = serviceItem.environments.find(env => env.key === 'qa');
    return {
      name: serviceItem.name,
      kind: serviceItem.kind,
      cloudName: serviceItem.cloudName,
      type: serviceItem.type,
      category: serviceItem.category,
      dev: devEnv,
      qa: qaEnv,
      aueuat: serviceItem.environments.find(
        env => env.key === 'uat' && env.location === 'aue',
      ),
      brsuat: serviceItem.environments.find(
        env => env.key === 'uat' && env.location === 'brs',
      ),
      safuat: serviceItem.environments.find(
        env => env.key === 'uat' && env.location === 'saf',
      ),
      weuuat: serviceItem.environments.find(
        env => env.key === 'uat' && env.location === 'weu',
      ),
      auepreprod: serviceItem.environments.find(
        env => env.key === 'preprod' && env.location === 'aue',
      ),
      brspreprod: serviceItem.environments.find(
        env => env.key === 'preprod' && env.location === 'brs',
      ),
      safpreprod: serviceItem.environments.find(
        env => env.key === 'preprod' && env.location === 'saf',
      ),
      weupreprod: serviceItem.environments.find(
        env => env.key === 'preprod' && env.location === 'weu',
      ),
      aueprod: serviceItem.environments.find(
        env => env.key === 'prod' && env.location === 'aue',
      ),
      brsprod: serviceItem.environments.find(
        env => env.key === 'prod' && env.location === 'brs',
      ),
      safprod: serviceItem.environments.find(
        env => env.key === 'prod' && env.location === 'saf',
      ),
      weuprod: serviceItem.environments.find(
        env => env.key === 'prod' && env.location === 'weu',
      ),
      aseprod: serviceItem.environments.find(
        env => env.key === 'prod' && env.location === 'ase',
      ),
      neuprod: serviceItem.environments.find(
        env => env.key === 'prod' && env.location === 'neu',
      ),
    };
  });
};

export const DatabaseAvailabilityList = () => {
  const tableCellStyle = { border: '1px solid #e0e0e0' };
  const columnHeaderStyle = { backgroundColor: '#f4f4f4', border: '1px solid #e0e0e0' };

  // Fetch database resource list
  const {
    data: serviceList,
    isFetching: isFetchingDBResource,
    error: dbResourceFetchError,
  } = useQueryDBResourceList();

  const {
    data: databaseAvailabilityList,
    isFetching: isChecking,
    error: fetchError,
  } = useQueryDatabaseAvailabilityStatus(serviceList ?? []);



  if (isFetchingDBResource || isChecking) {
    return <Progress />;
  } else if (dbResourceFetchError) {
    return <ResponseErrorPanel error={dbResourceFetchError} />;
  } else if (fetchError) {
    return <ResponseErrorPanel error={fetchError} />;
  } else {
    const data = generateColumns(databaseAvailabilityList ?? []);
    const columns: TableColumn[] = [
      {
        title: 'Category',
        field: 'category',
        render: (data: any) => <Team value={data.category} />,
        highlight: true,
        cellStyle: tableCellStyle,
        grouping: true,
        headerStyle: columnHeaderStyle,
      },
      {
        title: 'Component',
        field: 'name',
        render: (data: any) => <Service value={data.name} />,
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'Kind',
        field: 'kind',
        render: (data: any) => <Kind kind={data.kind} />,
        highlight: true,
        cellStyle: tableCellStyle,
        grouping: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'Dev',
        field: 'dev',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.dev}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'QA',
        field: 'qa',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.qa}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'AUE UAT',
        field: 'aueuat',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.aueuat}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'BRS UAT',
        field: 'brsuat',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.brsuat}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'SAF UAT',
        field: 'safuat',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.safuat}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'WEU UAT',
        field: 'weuuat',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.weuuat}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'AUE PreProd',
        field: 'auepreprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.auepreprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'BRS PreProd',
        field: 'brspreprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.brspreprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'SAF PreProd',
        field: 'safpreprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.safpreprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'WEU PreProd',
        field: 'weupreprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.weupreprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'AUE Prod',
        field: 'aueprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.aueprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'BRS Prod',
        field: 'brsprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.brsprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'SAF Prod',
        field: 'safprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.safprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'WEU Prod',
        field: 'weuprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.weuprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'ASE Prod',
        field: 'aseprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.aseprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
      {
        title: 'NEU Prod',
        field: 'neuprod',
        render: (data: any) => (
          <Status
            service={data.cloudName}
            kind={data.kind}
            type={data.type}
            env={data.neuprod}
          />
        ),
        highlight: true,
        cellStyle: tableCellStyle,
        disableClick: true,
        headerStyle: columnHeaderStyle
      },
    ];

    const title = `Database Status (${data?.length})`;

    return (
      <Table
        title={
          <Box display="flex" alignItems="left" style={{fontSize: 16}} >          
            <Box/>
            {title}        
          </Box>
        }
        options={{
          search: true,
          paging: false,
          padding: 'dense',
          sorting: false,
          grouping: false
        }}
        columns={columns}
        data={data}
        isLoading={isChecking}
        // components={{Toolbar: () => null}}
      />
    );
  }
};
