import React, { useEffect, useMemo, useState } from 'react';
import { DateTime } from 'luxon'
import { styled } from '@mui/material/styles';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { JiraProjectVersion } from '@internal/backstage-plugin-bidone-backend/src/api/jiraApi';

interface Props {
  loading?: boolean
  releases: JiraProjectVersion[];
  onReleaseSelected?: (release: string) => void
  defaultRelease?: string
}

const ReleaseList = styled(List)<{ component?: React.ElementType }>({
  '& .v5-MuiListItemText-primary': {
    fontWeight: 700
  }
});

export const ReleaseListComponent: React.FC<Props> = ({ releases, defaultRelease, onReleaseSelected }: Props) => {
  const [currentRelease, setCurrentRelease] = useState('')

  // Default release to select
  useEffect(() => {
    let initialRelease = defaultRelease
    // Not a valid release id in the hash url
    if (initialRelease && !releases.find(x => x.id === initialRelease)) {
      initialRelease = ''
    }

    if (!initialRelease) {
      // Default select 1st release
      initialRelease = releases[0]?.id
    }

    setCurrentRelease(initialRelease)

    if (onReleaseSelected) {
      onReleaseSelected(initialRelease)
    }
  }, [releases, defaultRelease])

  // List active index
  const selectedIndex = useMemo(() => {
    return releases?.findIndex(x => x.id === currentRelease)
  }, [currentRelease, releases])

  return (
    <ReleaseList dense>
      {releases.map((x, i) => {
        const { id } = x
        return (
          <ListItem key={id}>
            <ListItemButton selected={selectedIndex === i} onClick={() => {
              setCurrentRelease(id)
              if (onReleaseSelected) {
                onReleaseSelected(id)
              }
            }}>
              <ListItemText primary={x.name}
                secondary={x.releaseDatetime && DateTime.fromISO(x.releaseDatetime).toLocaleString(DateTime.DATE_MED)} />
            </ListItemButton>
          </ListItem>
        )
      })}
    </ReleaseList>
  )
};